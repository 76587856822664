import { Address, useContractRead } from 'wagmi'
import abiGenesys from 'helpers/abi-genesys.json'
import { useMemo } from 'react'
import { StartTime } from 'pages/mint/Mint'

const useGetRound = () => {
    const { data: dataStart0 } = useContractRead({
        address: process.env.REACT_APP_CONTRACT_GENESYS as Address,
        abi: abiGenesys,
        functionName: "_startTime",
        args: [0],
        watch: false,
        // cacheTime: 2_000,
    })
    const { data: dataStart1 } = useContractRead({
        address: process.env.REACT_APP_CONTRACT_GENESYS as Address,
        abi: abiGenesys,
        functionName: "_startTime",
        args: [1],
        watch: false,
        // cacheTime: 2_000,
    })
    const { data: dataStart2 } = useContractRead({
        address: process.env.REACT_APP_CONTRACT_GENESYS as Address,
        abi: abiGenesys,
        functionName: "_startTime",
        args: [2],
        watch: false,
        // cacheTime: 2_000,
    })

    const startTime = useMemo(() => {
        // console.log(+(dataStart0 || 0))
        return {
            whitelist: Number(dataStart0 || 0),
            waitlist: Number(dataStart1 || 0),
            public: Number(dataStart2 || 0)
        } as StartTime
    }, [dataStart0, dataStart1, dataStart2])

    return { startTime }
}

export default useGetRound