import { useEffect, useMemo, useRef, useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import iconInput from "assets/images/icon-input-range.png"
import numeral from 'numeral'

interface IInputRange {
    totalMinted: number,
    totalSupply: number
}

const InputRange = ({ totalMinted, totalSupply }: IInputRange) => {

    return (
        <Wrap>
            <div className='fill' style={{ width: `${(totalMinted / totalSupply) * 100}%` }}>
                <div className="cursor-fill"></div>
            </div>
            <span className="ir-mint size-2 color-white">{numeral(totalMinted).format("0,0")}</span>
            <span className="ir-supply size-2 color-white">{numeral(totalSupply).format("0,0")}</span>
        </Wrap>
    )
}

export default InputRange

const Wrap = styled.div`
    height: 16px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100px;
    width: 100%;
    display: flex;
    position: relative;
    .fill {
        height: 100%;
        border-radius: 100px;
        background: #00E8F6;
        position: relative;
        transition: 0.3s;
        .cursor-fill {
            position: absolute;
            right: 0;
            width: 32px;
            height: 32px;
            top: 50%;
            transform: translate(20%, -50%);
            background-image: url(${iconInput});
            background-size: contain;
        }
       
    }
    .ir-supply {
        position: absolute;
        right: 0;
        width: fit-content;
        top: 28px;
    }
    .ir-mint {
        position: absolute;
        left: 0;
        width: fit-content;
        top: 28px;
    }
`

