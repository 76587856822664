import { toast } from 'react-toastify';
interface IToastNotify {
    type: "success" | "error" | "info",
    text: string
}
export const toastNotify = ({ type, text }: IToastNotify) => {
    switch (type) {
        case "success": {
            toast.success(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        }
        case "error": {
            toast.error(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        }

        default: {
            toast.info(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        }
    }
    return null;
}

