import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import configColor from 'constants/configColor'
import LoadingSpinner from './LoadingSpinner'
import iconCopy from 'assets/images/icon-copy.png'
import iconShare from 'assets/images/icon-share.png'
import iconSetting from 'assets/images/icon-setting.png'
import { breakpointsMedias } from 'constants/breakpoints'

interface IB extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string,
    isLoading?: boolean,
    sizeBt?: "small" | "normal" | "tiny",
    className?: string,
    disabled?: boolean,
    variant?: "fill" | "border",
    classNameChild?: string
}

const Button = ({ text, isLoading = false, sizeBt = "normal", className, disabled = false, variant = "border", classNameChild, ...props }: IB) => {
    const { t } = useTranslation();
    return (
        <Wrap className={`${isLoading ? "bt-loading" : ""} bt-${sizeBt} ${className} style-${variant}`} disabled={disabled || isLoading} {...props}>
            <div>
                <span className={`color-black size-2 ${classNameChild}`}>{!!isLoading ? <LoadingSpinner />
                    : t(text)}</span>
            </div>
        </Wrap>
    )
}
export default Button

const Wrap = styled.button`
    /* max-width: 100%; */
    width: 100%;
    padding: 0 12px;
    height: 36px;
    border-radius: 4px;
    background: linear-gradient(94.4deg, #5196FF 2.87%, #4E68EF 49.07%, #6F42EF 100%);
    padding: 0;
    overflow: hidden;
    display: flex;
    > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
            color: ${configColor.colorPrimary};
        }
    }
    &.style-border{
        padding: 1px;
        > div {
            background: linear-gradient(165deg, #5C5E8E 0%, #5C5E8E 10.95%, #3D2567 55.14%, #3D2567 100%);
            border-radius: 4px;
        }
        :hover {
            /* background: ${configColor.colorPrimary}; */
        }
    }
    &.bt-small {
        height: 28px;
        border-radius: 6px;
        padding: 0 7px;
        min-width: 68px;
    }
    /* &:active,
    &:focus, */
    &:hover {
        background: linear-gradient(274.4deg, #5196FF 2.87%, #4E68EF 49.07%, #6F42EF 100%);
        > span {
            color: ${configColor.colorPrimary};
        }
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled{
        opacity: 0.4;
        cursor: not-allowed;
    }
    &.bt-loading {
        opacity: 0.4;
        cursor: not-allowed;
        :hover {
            background: ${configColor.colorBG};
            border: 1px solid ${configColor.colorPrimary};
        }
    }
    > span {
        text-align: center;
    }
    @keyframes rotate-center-2 {
        0% {
            transform: rotate(0);
        }
        100% {
           transform: rotate(360deg);
        }
    }
    ${breakpointsMedias.min1200} {
        height: 46px;
        &.bt-small {
            height: 32px;
        }
    }
`