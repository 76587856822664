import {
  EthereumClient,
  w3mConnectors,
  w3mProvider
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { chains } from "constants/chains";
import "language/I18n";
import ReactDOM from 'react-dom/client';
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID || ''
if (!PROJECT_ID) {
  throw new Error("Project ID is required");
}
const { publicClient } = configureChains(chains, [w3mProvider({ projectId: PROJECT_ID })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId: PROJECT_ID, version: 1, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

const location = window.location.origin
root.render(
  <>
    <WagmiConfig config={wagmiConfig}>
      <App />
    </WagmiConfig>
    <Web3Modal
      projectId={PROJECT_ID}
      ethereumClient={ethereumClient}
      defaultChain={chains[0]}
      themeVariables={{
        '--w3m-font-family': 'Roboto, sans-serif',
        "--w3m-logo-image-url": `${location}/logo-full.svg`,
        "--w3m-background-color": '#0C0B0D',
        "--w3m-background-border-radius": "16px"
      }}
      themeMode="dark"
    />
  </>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
