import { useWidthScreen } from 'helpers/useScreen'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import Menu from './Menu'
import MenuButton from './MenuButton'
import { breakpointsMedias } from 'constants/breakpoints'

const Header = () => {
    const [show, setShow] = useState<boolean>(false);
    const { width } = useWidthScreen();

    useEffect(() => {
        window.onscroll = function () {
            // We add pageYOffset for compatibility with IE.
            if (window.scrollY >= 10 || window.pageYOffset >= 10) {
                document.getElementById("header")?.classList.add("has-color");
            } else {
                document.getElementById("header")?.classList.remove("has-color");
            }
        };
    }, [])

    return (
        <Wrap className='' id="header">
            <div className="wrap-header">
                <div className="logo">
                    <Logo />
                </div>
                <div className="menu ">
                    {width >= 1200 ? <Menu onClose={() => { }} />
                        : <MenuButton onClick={() => { setShow(!show) }} show={show} />}
                </div>
            </div>
            {show && <div className='bg-shadow'></div>}
            <div className={`menu-mobile ${show && "menu-mobile-active"}`} onClick={() => { setShow(false) }}>
                <Menu onClose={() => { setShow(false) }} />
            </div>
        </Wrap>
    )
}

export default Header

const Wrap = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    &.has-color {
        background: #251C40;
    }
    .wrap-header {
        display: flex;
        align-items: center;
        padding: 0 16px;
        /* background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%); */
        width: 100%;
        height: 60px;
        z-index: 1;
        .menu {

        }
        .logo {
            margin-right: auto;
        }
    }
    .menu-mobile {
        position: fixed;
        width: 100%;
        height: 100%;
        transition: 0.3s;
        top: 0;
        right: -100%;
        z-index: 0;
        /* background-color: #000000d0; */
    }
    .menu-mobile-active {
        right: 0;
        display: flex;
        justify-content: flex-end;
    }
    .bg-shadow {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 0;
        background-color: #000000d0;
    }
    ${breakpointsMedias.min1200} {
        position: relative;
        &.has-color {
            background: unset;
        }
        .wrap-header {
            .menu {
                flex: 1;
            }
        }
        .menu-mobile {
            display: none;
        }
        .bg-shadow {
            display: none;
        }
    }
    ${breakpointsMedias.min1200} {
        justify-content: center;
        .wrap-header {
            max-width: 1540px;
            padding: 0 54px;
            height: 130px;
            .menu {
                flex: 1;
            }
        }
        .menu-mobile {
            display: none;
        }
    }
`