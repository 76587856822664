/* eslint-disable import/no-anonymous-default-export */
export default {
  colorWhite: "#ffffff",
  colorPrimary: "#ffffff",
  bgPrimary: "#000000",
  black1: "#0C0B0D",
  gray1: "#19191E",
  gray2: "#272730",
  gray3: "#383841",
  gray4: "#D9D9D9",
  gray5: "#3F3F4D",
  colorGray: "#D5D9EA",
  lightGray: "#828282",
  lightWhite: "#E0E0E0",
  white: "#ffffff",
  red2: "#DC3030",
  green2: "#37C071",
  red3: "#FF3B2F",
  green3: "#00FF6C",
  colorSecondary: "#c1ce06b0",
  colorWhite2: "#E0E0E0",
  colorGreen: "#37C071",
  colorRed: "#DC3030",
  colorBG: "#0C0B0D",
  colorCyan: "#00E8F6",
};
