import { Address, useContractRead } from 'wagmi'
import abiGenesys from 'helpers/abi-genesys.json'
import { useMemo } from 'react'

const useGetTotalMinted = () => {
    const { data: dataMinted } = useContractRead({
        address: process.env.REACT_APP_CONTRACT_GENESYS as Address,
        abi: abiGenesys,
        functionName: "totalSupply",
        watch: true,
        cacheTime: 2_000,
    })

    const totalMinted = useMemo(() => {
        if (dataMinted) {
            return Number(dataMinted)
        }
        return 0
    }, [dataMinted])

    return { totalMinted }
}

export default useGetTotalMinted