import Header from 'components/header/Header';
import { chains } from 'constants/chains';
import { GlobalStyle } from 'constants/globalStyled';
import Apollo from 'contexts/Apollo';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routers from 'routers';
import { useNetwork, useSwitchNetwork } from 'wagmi';

const DEFAULT_CHAIN = chains[0].id
function App() {
  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    if (chain?.id !== DEFAULT_CHAIN) {
      switchNetwork && switchNetwork(DEFAULT_CHAIN)
    }
  }, [chain]) 

  return (
    <>
      <GlobalStyle />
      <Apollo>
        <BrowserRouter>
          <Header />
          <Routers />
        </BrowserRouter>
      </Apollo>
      <ToastContainer />
    </>
  );
}

export default App;
