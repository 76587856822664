import { useWeb3Modal } from '@web3modal/react'
import iconNetwork from 'assets/images/icon-network.png'
import Button from 'components/core/Button'
import { breakpointsMedias } from 'constants/breakpoints'
import { chains } from 'constants/chains'
import { subStringAddress } from 'helpers/subStringAddress'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'

const Wallet = () => {
    const { disconnect } = useDisconnect()
    const { chain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()
    const { t } = useTranslation()
    const { open } = useWeb3Modal()
    const { isConnected, address } = useAccount()

    useEffect(() => {

    }, [])

    const DEFAULT_CHAIN = chains[0].id

    return (
        <Wrap onClick={(e) => { e.stopPropagation() }}>
            {
                (isConnected) ?
                    !(chain?.id === DEFAULT_CHAIN) ?
                        <div className='wallet-wrap ww-2'>
                            <span className='size-0'>{t("wrongNetwork")}  {`(${chains.length && chains[0].name})`}</span>
                            {/* <div className='sm:hidden'>
                                <AiOutlinePoweroff size={"1.5rem"} className="text-primary" onClick={() => disconnect()} />
                            </div> */}
                            <div className='ww-bt'>
                                <Button className='' text={`Disconnect`} onClick={() => disconnect()} />
                            </div>
                        </div>
                        :
                        <div className='wallet-wrap'>
                            <span className='size-1'> {subStringAddress(address)}</span>
                            <div className='ww-icon' onClick={() => open()}  >
                                <img alt="arbitrum" className='' src={iconNetwork} />
                            </div>
                        </div>
                    :
                    <Button variant='fill' className='' text='Connect wallet' onClick={() => open()} />
            }
        </Wrap>
    )
}

export default Wallet;

const Wrap = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    max-width: 230px;
    .wallet-wrap {
        display: flex;
        align-items: center;
        .ww-bt {
            width: 150px;
        }
        .ww-icon {
            width: 40px;
            height: fit-content;
            margin-left: 10px;
            > img {
                width: 100%;
                height: auto;
            }
        }
    }
    > button {
        min-width: 160px;
    }
    ${breakpointsMedias.min1200} {
        margin-left: 32px;
        max-width: 230px;
        .ww-2 {
            > span {
                flex: 1;
                width: 130px;
            }
            margin-right: -40px;
            .ww-bt {
                width: 110px;
                /* margin-left: 10px; */
            }
        }
    }
    ${breakpointsMedias.min1360} {
        margin-left: 30px;
    }
`