import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import bgMenu from 'assets/images/bg-menu.png'
import ballLight from 'assets/images/ball-light.png'
import iconDiscord from 'assets/images/icon-discord.png'
import iconInsta from 'assets/images/icon-instagram.png'
import iconTwitter from 'assets/images/icon-twitter.png'
import iconOpensea from 'assets/images/icon-opensea.png'
import iconDiscord2 from 'assets/images/icon-discord.svg'
import iconInsta2 from 'assets/images/icon-instagram.svg'
import iconTwitter2 from 'assets/images/icon-twitter.svg'
import iconOpensea2 from 'assets/images/icon-opensea.svg'
import { useTranslation } from 'react-i18next';
import Wallet from './Wallet'
import iconLink from 'assets/images/icon-link.png'
import configColor from 'constants/configColor'
import { breakpointsMedias } from 'constants/breakpoints'

interface IMenu {
    onClose: () => void
}

export const MENU_LIST = [
    {
        text: "team",
        link: "/team",
    },
    {
        text: "faqs",
        link: "/faqs",
    },
    {
        text: "mint",
        link: "/mint",
    },
    {
        text: "document",
        link: "https://twitter.com/TheMeoNFT",
        exlink: true
    },
];
export const SOCIAL_LIST = [
    {
        text: "twitter",
        link: "https://twitter.com/TheMeoNFT",
        icon: iconTwitter,
        icon2: iconTwitter2,
    },
    {
        text: "discord",
        link: "https://discord.gg/themeo",
        icon: iconDiscord,
        icon2: iconDiscord2,
    },
    // {
    //     text: "instagram",
    //     link: "/",
    //     icon: iconInsta,
    //     icon2: iconInsta2,
    // },
    {
        text: "opensea",
        link: "/",
        icon: iconOpensea,
        icon2: iconOpensea2,
    },
];

const Menu = ({ onClose }: IMenu) => {
    const { pathname } = useLocation();
    const { t } = useTranslation()
    return (
        <Wrap >
            <div className={`menu-list`}>
                {
                    MENU_LIST.map((item, index) =>
                        <Link to={item.link}
                            key={index}
                            className={`menu-item ${pathname.split("/")[1] === item.text ? "menu-item-active" : ""}`}
                            onClick={() => {
                                onClose()
                            }}
                            target={item.exlink ? "_blank" : ""}
                        >
                            <img src={iconLink} alt="" />
                            <span className={`size-2 color-white`}>{t(item.text)}</span>
                        </Link>)
                }
                {
                    SOCIAL_LIST.map((item, index) =>
                        <a href={item.link}
                            key={index}
                            target="_blank" rel="noreferrer"
                            className={`menu-item social-item`}
                            onClick={() => {
                                onClose()
                            }}
                        >
                            <span className={`size-2 color-white`}>{t(item.text)}</span>
                            <div className="mi-icon-big">
                                <SocicalItem active={item.icon2} />
                            </div>

                        </a>)
                }
            </div>
            <Wallet />
        </Wrap>
    )
}

export default Menu

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #000000;
    position: relative;
    max-width: 475px;
    background-image: url(${bgMenu});
    padding: 57px 20px;
    .menu-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px 0;
        z-index: 0;
        border-top: 1px solid rgba(175, 236, 255, 0.16);
        align-items: flex-end;
        .menu-item {
            /* margin-bottom: 16px; */
            cursor: pointer;
            color: ${configColor.lightWhite};
            display: flex;
            align-items: center;
            text-transform: uppercase;
            width: fit-content;
            margin-bottom: 28px;
            margin-top: 12px;
            > img {
                display: none;
            }
            > span {
                display: flex;
                align-items: center;
                font-size: 18px;
            }
            .mi-icon {
                width: 24px;
                height: 24px;
                display: flex;
                margin-left: 12px;
                > img {
                    object-fit: contain;
                }
            }
            /* .mi-icon-big {
                display: none;
            } */
            &:hover{
                > span {
                    color: ${configColor.colorCyan};
                }
            }
        }
        .menu-item-active {
            padding: 12px 24px;
            background: rgba(175, 236, 255, 0.16);
            border-radius: 8px;
            > img {
                display: block;
                height: 24px;
                width: auto;
                margin-right: 8px;
            }
            > span {
               color: ${configColor.colorCyan};
            }
        }
        .social-item {
            margin-bottom: 16px;
            > span {
                margin-right: 12px;
            }
            .mi-icon-big {
                    display: flex;
                    width: 48px;
                    height: 48px;
                    background: rgba(175, 236, 255, 0.16);
                    border-radius: 8px;
                    padding: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    > div {
                        width: 100%;
                        height: 100%;
                        transition: 0.5s;
                    }
                }
        }
    }
    ${breakpointsMedias.min1200} {
        flex-direction: row;
        background-color: transparent;
        max-width: unset;
        background: none;
        padding: 0;
       
        .menu-list {
            border: none;
            flex-direction: row;
            align-items: center;
            padding: 0;
            .menu-item {
                padding: 0 12px;
                margin-bottom: 0;
                margin: 0 8px;
            }
            .menu-item-active {
                padding: 10px 16px;
            }
            .social-item {
                margin: 0;
                margin-left: 12px;
                padding: 0;
                > span {
                    display: none;
                }
                
                &:nth-child(5) {
                    margin-left: auto;
                }
            }
        }
    }
    ${breakpointsMedias.min1360} {
        .menu-list {
            .menu-item {
                padding: 0 24px;
                margin: 0 8px;
            }
            .menu-item-active {
                padding: 12px 24px;
            }
            .social-item {
                margin: 0;
                margin-left: 16px;
                padding: 0;
            }
        }
    }
`

interface ISocicalItem {
    active: string
}

const SocicalItem = styled.div`
    background-color: #FFFFFF;
    -webkit-mask: url(${({ active }: ISocicalItem) => active}) no-repeat center;
    mask: url(${({ active }: ISocicalItem) => active}) no-repeat center;
    mask-size: contain;
    /* transition: 0.6s ease-in-out; */
    &:hover {
        background: ${configColor.colorCyan};
    }
`