import { Address, useContractWrite, useWaitForTransaction } from 'wagmi'
import { useEffect } from 'react'
import abiGenesys from 'helpers/abi-genesys.json'
import { toastNotify } from './toastNotify'
import { BigNumber, ethers } from 'ethers'

interface IMintWhitelist {
    quantity: number,
    level: number,
    proof: string[]
}

const useMintWhitelist = ({ quantity, level, proof }: IMintWhitelist) => {
    const { data: dataMint, write, isLoading, isError: errorStart, error } = useContractWrite(
        {
            address: process.env.REACT_APP_CONTRACT_GENESYS as Address,
            abi: abiGenesys,
            functionName: "mintWhitelist",
            args: [quantity, level, proof],
        }
    )

    const { isLoading: checkLoading, isError, isSuccess, error: error2 } = useWaitForTransaction({
        hash: dataMint?.hash,
    })

    const onMint = () => {
        try {
            if (write) {
                write({
                    value: BigInt(Number(process.env.REACT_APP_PRICE_WHITELIST) * quantity),
                })
            }
        } catch (error) {
            toastNotify({ type: "error", text: "Mint fail" })
            console.log("ERROR Mint", error)
        }
    }

    useEffect(() => {
        if (error2) {
            toastNotify({ type: "error", text: error2.message })
        }
        if (error) {
            toastNotify({ type: "error", text: error.message })
        }
    }, [error2, error])

    useEffect(() => {
        if (isSuccess) {
            toastNotify({ type: "success", text: "Mint Succesful" })
        }
    }, [isSuccess])

    return { onMint, isMintSuccess: isSuccess, isLoadingMint: isLoading || checkLoading }
}

export default useMintWhitelist