import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { useAccount } from 'wagmi';

const linkApi = (process.env.REACT_APP_API_APOLLO || "") as string
const linkWsApi = (process.env.REACT_APP_WS_APOLLO || "") as string
const Apollo = ({ children }: any) => {
    const { address } = useAccount()
    const httpLink = createHttpLink({
        uri: linkApi,
        credentials: "same-origin"
    });
    const wsLink = new GraphQLWsLink(createClient({
        url: linkWsApi,
        retryAttempts: 4500
    }));
    wsLink.client.on('connected', () => {
        console.log('WebSocket connection established');
    });
    wsLink.client.on('error', () => {
        console.log('WebSocket connection error');
    });
    wsLink.client.on('closed', () => {
        console.log('WebSocket  closed');
    });

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: address ? address : "",
            }
        }
    });
    const link = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        wsLink as any,
        authLink.concat(httpLink) as any
    );

    const client = new ApolloClient({
        link: link,
        cache: new InMemoryCache()
    });

    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}

export default Apollo