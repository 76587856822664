import { breakpointsMedias } from 'constants/breakpoints'
import { useEffect, useMemo, useRef, useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IMintCountDown {
    onEnd: () => void,
    start: number,
    classic?: boolean
}

const MintCountDown = ({ start, onEnd, classic }: IMintCountDown) => {
    const { t } = useTranslation()

    // console.log(start - Date.now())

    const renderer = ({ hours, minutes, seconds, completed }: any) => {
        // console.log({ hours, minutes, seconds, completed })
        return classic ? <span className="color-white size-1">{hours}h {minutes}m {seconds}s</span>
            : <Wrap>
                <div className="mcd mcd-1">
                    <div className="mcd-value">
                        <span className="size-4 color-black">{hours}</span>
                    </div>
                    <span className='size-1 color-gray'>{t("hours")}</span>
                </div>
                <span className="color-gray size-4">:</span>
                <div className="mcd mcd-1">
                    <div className="mcd-value">
                        <span className="size-4 color-black">{minutes}</span>
                    </div>
                    <span className='size-1 color-gray'>{t("minutes")}</span>
                </div>
                <span className="color-gray size-4">:</span>
                <div className="mcd mcd-1">
                    <div className="mcd-value">
                        <span className="size-4 color-black">{seconds}</span>
                    </div>
                    <span className='size-1 color-gray'>{t("seconds")}</span>
                </div>
            </Wrap>
    };

    return (
        <Countdown
            date={start}
            renderer={renderer}
            onComplete={() => {
                // console.log("Enddd")
                onEnd()
            }}
            autoStart
            key={Math.random() * 100}
        />

        // <Wrap>
        //     <div className="mcd mcd-1">
        //         <div className="mcd-value">
        //             <span className="size-4 color-black">{renderTime.hour}</span>
        //         </div>
        //         <span className='size-1 color-gray'>{t("hours")}</span>
        //     </div>
        //     <span className="color-gray size-4">:</span>
        //     <div className="mcd mcd-1">
        //         <div className="mcd-value">
        //             <span className="size-4 color-black">{renderTime.minute}</span>
        //         </div>
        //         <span className='size-1 color-gray'>{t("minutes")}</span>
        //     </div>
        //     <span className="color-gray size-4">:</span>
        //     <div className="mcd mcd-1">
        //         <div className="mcd-value">
        //             <span className="size-4 color-black">{renderTime.second}</span>
        //         </div>
        //         <span className='size-1 color-gray'>{t("seconds")}</span>
        //     </div>
        // </Wrap>
    )
}

export default MintCountDown

const Wrap = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24px;
    .mcd {
        display: flex;
        flex-direction: column;
        align-items: center;
        .mcd-value {
            width: 100px;
            height: 80px;
            background: #FFFFFF;
            border: 1px solid rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(5.6px);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;
            > span {
                font-size: 40px;
             
            }
        }
        > span {
            text-transform: uppercase;
            font-size: 16px;
        }
    }
    > span {
        font-size: 40px;
        text-transform: uppercase;
        margin: 0 32px 10px 32px;
    }
    ${breakpointsMedias.max991} {
        margin-top: 24px;
        .mcd {
            .mcd-value {
                width: 64px;
                height: 48px;
                > span {
                    font-size: 24px;
                }
            }
            > span {
                font-size: 14px;
            }
        }
        > span {
            font-size: 24px;
            margin: 0 16px 10px 16px;
        }
    }
`

