import iconCoin from 'assets/images/icon-link.png'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import { useEffect, useState } from 'react'
import Button from 'components/core/Button'
import { useWeb3Modal } from '@web3modal/react'
import Countdown from 'react-countdown'
import useMintPublic from 'helpers/useMintPublic'
import useGetMaxSupply from 'helpers/useGetMaxSupply'
import useGetTotalMinted from 'helpers/useGetTotalMinted'
import { gql, useApolloClient } from '@apollo/client'
import useGetRound from 'helpers/useGetRound'
import numeral from 'numeral'
import useMintWhitelist from 'helpers/useMintWhitelist'
import useMintWaitlist from 'helpers/useMintWaitlist'
import useCheckMinted from 'helpers/useCheckMinted'
import { NumericFormat } from 'react-number-format'
import MintCountDown from './MintCountDown'
import bgTeam from 'assets/images/bg-team.png'
import WrapFrame from 'components/core/WrapFrame'
import imgMint from 'assets/images/img-mint.png'
import { breakpointsMedias } from 'constants/breakpoints'
import imgMintDone from 'assets/images/img-mint-done.png'
import bgMintDone from 'assets/images/bg-mint-done.png'
import bgMintDone2 from 'assets/images/bg-mint-done-2.png'
import InputRange from './InputRange'

const dataRole = {
    ["Whitelist" as Role]: {
        text1: "Whitelist",
        text2: "You are in Whitelist. You can mint nft on ",
        text3: "all Rounds"
    },
    ["Waitlist" as Role]: {
        text1: "Waitlist",
        text2: "You are in Waitlist. You can mint nft on ",
        text3: "Waitlist Round & Public Round"
    },
    ["Public" as Role]: {
        text1: "Public User",
        text2: "You can only mint nft on ",
        text3: "Public Round"
    }
}

const dataPrice = {
    whitelist: Number(process.env.REACT_APP_PRICE_WHITELIST),
    waitlist: Number(process.env.REACT_APP_PRICE_WAITLIST),
    public: Number(process.env.REACT_APP_PRICE_PUBLIC)
}

export type Role = "Whitelist" | "Waitlist" | "Public"

export const GET_USER = gql`
query user(
    $address: String!
) {
    user (
        address: $address
    ) {
        address
        verifyData {
            level
            proof
        }
    }
  }
`

export type StartTime = {
    whitelist: number,
    waitlist: number,
    public: number
};
type DataStateRound = {
    round: keyof StartTime,
    live: boolean
} | null

type DataUser = {
    level: 0 | 1 | 2 | 3,
    proof: [string]
}

const roundDuration = Number(process.env.REACT_APP_ROUND_DURATION)

const Mint = () => {
    const client = useApolloClient();
    const { t } = useTranslation()
    const { isConnected, address } = useAccount()
    const { open } = useWeb3Modal();
    const [stateRound, setStateRound] = useState<DataStateRound | null>(null)
    const [amount, setAmount] = useState<number>(1)
    const totalSupply = Number(process.env.REACT_APP_MAX_SUPPLY)
    const { totalMinted } = useGetTotalMinted();
    const { minted } = useCheckMinted({ address: address || "", round: stateRound ? (stateRound.round === "whitelist" ? 0 : stateRound.round === "waitlist" ? 1 : 2) : 2 });
    const [dataUser, setDataUser] = useState<DataUser | null>(null)
    const { startTime } = useGetRound();
    const [k, setK] = useState(false);

    const { onMint: onMintWhitelist, isLoadingMint: isLoadingMint0 } = useMintWhitelist({ quantity: amount, level: dataUser ? dataUser.level : 0, proof: dataUser ? dataUser.proof : [] })
    const { onMint: onMintWaitlist, isLoadingMint: isLoadingMint1 } = useMintWaitlist({ level: dataUser ? dataUser.level : 0, proof: dataUser ? dataUser.proof : [] })
    const { onMint: onMintPublic, isLoadingMint: isLoadingMint2 } = useMintPublic()

    const calTimeRound = () => {
        setK(!k)
        let role = checkUser() as any;
        let now = Math.floor(Date.now() / 1000);
        // console.log({ now, role, startTime })
        if (now < startTime.whitelist) {
            if (role === "Whitelist") {
                return setStateRound({
                    round: "whitelist",
                    live: false,
                })
            }
            if (role === "Waitlist") {
                return setStateRound({
                    round: "waitlist",
                    live: false,
                })
            }
            return setStateRound({
                round: "public",
                live: false,
            })
        }
        if (now < startTime.waitlist) {
            if (role === "Whitelist") {
                setAmount(dataUser ? dataUser.level - minted : 1)
                return setStateRound({
                    round: "whitelist",
                    live: true,
                })
            }
            if (role === "Waitlist") {
                return setStateRound({
                    round: "waitlist",
                    live: false,
                })
            }
            return setStateRound({
                round: "public",
                live: false,
            })
        }
        if (now < startTime.public) {
            if (role !== "Public") {
                return setStateRound({
                    round: "waitlist",
                    live: true,
                })
            }
            return setStateRound({
                round: "public",
                live: false,
            })
        }
        return setStateRound({
            round: "public",
            live: true
        })
    }

    useEffect(() => {
        if (address && isConnected && startTime) {
            calTimeRound()
        }
    }, [address, isConnected, startTime, dataUser])

    const renderer = ({ hours, minutes, seconds, completed }: any) => {
        if (completed) {
            // Render a completed state
            return <span>0s</span>;
        } else {
            // Render a countdown
            return <span>{hours}h {minutes}m {seconds}s</span>;
        }
    };

    useEffect(() => {
        if (address && isConnected)
            getUser()
        // Get total Minted
    }, [address, isConnected])

    const getUser = () => {
        // console.log("EEE")
        try {
            const query = client.watchQuery({
                query: GET_USER,
                variables: {
                    address: address,
                },
                fetchPolicy: "no-cache"
            })
            const querySubscribe = query.subscribe(({ data: { user } }) => {
                // console.log(user)
                if (user) {
                    setDataUser(user.verifyData)
                }
            }, (error) => {
                console.log("Get user error", error);
            })
            return querySubscribe
        } catch (error) {
            console.log("Get user err", error);
        }
    }

    const checkUser = () => {
        if (dataUser) {
            if (dataUser.level > 0) {
                return "Whitelist"
            }
            return "Waitlist"
        }
        return "Public"
    }

    return (<Wrap>
        {totalMinted < totalSupply && <div className="mint-img">
            <img src={imgMint} alt="" />
        </div>}
        <div className="container">
            {(address && isConnected) ? <WrapFrame className={`mint-wrap ${totalMinted >= totalSupply && "mint-wrap-full"}`}>
                <>
                    <div className='mint'>
                        <div className="mint-role">
                            <span className="size-4 color-white">{checkUser()} {checkUser() !== "Whitelist" && "User"}&nbsp;</span>
                            {(dataUser && dataUser.level > 0) && <span className="size-4 color-blue">Level&nbsp;{dataUser.level}</span>}
                        </div>
                        <span className="size-1 color-gray mint-role-2">{dataRole[checkUser()].text2}&nbsp;<span className="size-1 color-white">{dataRole[checkUser()].text3} </span></span>
                        {(stateRound && !stateRound.live) && <div className='mint-start'>
                            <span className="size-1 color-white">You can mint nft in&nbsp;<span className="size-2 text-capital">{stateRound.round} Round&nbsp;</span>:</span>
                            <MintCountDown onEnd={calTimeRound} start={startTime[stateRound.round] * 1000 || 0} />
                        </div>}
                        {(stateRound && stateRound.live) && <>
                            {totalMinted < totalSupply && <>
                                <div className="mint-action">
                                    <div className="ma-row">
                                        <span className='size-3 color-white'>{stateRound.round} Round</span>
                                        <div className="mar-price">
                                            <span className='size-1 color-white'>{dataPrice[stateRound.round] / 1e18}</span>
                                            <img src={iconCoin} alt="" />
                                        </div>
                                    </div>
                                    <div className="ma-row">
                                        {/* <span className='size-2 color-white'>Minted&nbsp;<span className='size-2 color-blue'>{mintedRound || "?"} / {dataRound[stateRound.round].max}</span></span> */}

                                        {(stateRound.round === "whitelist" && stateRound.live) && <div className="amount-input">
                                            <div onClick={() => { setAmount(amount >= 2 ? amount - 1 : 1) }} >
                                                <span className="size-2">-</span>
                                            </div>
                                            <div className="aii">
                                                <NumericFormat
                                                    allowNegative={false}
                                                    value={amount}
                                                    decimalScale={0}
                                                    readOnly
                                                    className="color-white size-1 aii-input"
                                                    isAllowed={(values: any) => values.floatValue <= 3}
                                                    onValueChange={(values) => {
                                                        setAmount(values.floatValue || 0)
                                                    }} />
                                            </div>
                                            <div onClick={() => { setAmount(amount <= (dataUser ? dataUser.level - 1 : 2) ? amount + 1 : (dataUser ? dataUser.level : 3)) }}>
                                                <span className="size-2">+</span>
                                            </div>
                                        </div>}
                                        <Button
                                            text='Mint'
                                            variant='fill'
                                            disabled={(stateRound.round === "whitelist" && minted >= (dataUser ? dataUser.level : 3))
                                                || (minted >= 1 && stateRound.round !== "whitelist")
                                            }
                                            isLoading={isLoadingMint0 || isLoadingMint1 || isLoadingMint2}
                                            onClick={() => {
                                                if (stateRound.round === "whitelist") {
                                                    onMintWhitelist()
                                                }
                                                if (stateRound.round === "waitlist") {
                                                    onMintWaitlist()
                                                }
                                                if (stateRound.round === "public") {
                                                    onMintPublic()
                                                }
                                            }} />
                                    </div>
                                </div>
                                {stateRound.round !== "public" && <span className="size-1 mint-end">Ends in:&nbsp;
                                    <span className="size-2">
                                        <MintCountDown classic onEnd={calTimeRound} start={((startTime[stateRound.round] || 0) + roundDuration) * 1000} />
                                    </span>
                                </span>}
                            </>}
                            <div className="mint-range">
                                <InputRange totalSupply={totalSupply} totalMinted={totalMinted} />
                            </div>
                            {/* <div className="mint-total-mint">
                                <span className='size-2'>Total Minted : </span>
                                <span className='size-2'>{numeral(totalMinted).format("0,0")} / {numeral(totalSupply).format("0,0")}</span>
                            </div> */}
                        </>}
                    </div>
                    {totalMinted >= totalSupply &&
                        <div className='mint-done'>
                            <div className="md-img">
                                <img src={imgMintDone} alt="" />
                            </div>
                        </div>}
                </>
            </WrapFrame >
                : <WrapFrame className='mint-nologin'>
                    <>
                        <span className="size-3 color-white text-center">Login to Minting</span>
                        <Button text='Connect wallet' onClick={() => open()} />
                    </>
                </WrapFrame>}
        </div>

    </Wrap >)
}

export default Mint

const Wrap = styled.div`
    width: 100%;
    min-height: 100vh;
    /* height: 100vh; */
    background-image: url(${bgTeam});
    background-size: cover;
    background-position: top center;
    display: flex;
    overflow: hidden;
    /* align-items: center; */
    justify-content: center;
    position: relative;
    margin-top: -130px;
    padding-top: 172px;
    padding-bottom: 54px;
    flex-direction: row;
    align-items: flex-start;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #204d65da 0%, #343968d5 28.79%, #472064dd 79.26%);
        z-index: 0;
        top: 0;
        left: 0;
    }
    .container {
        z-index: 1;
        display: flex;
    }
    .mint-wrap {
        width: 60%;
        border-radius: 8px;
        display: flex;
        min-height: 70vh;
        max-height: 819px;
        .mint {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 64px 80px;
            border-radius: 8px;
        }
        .mint-done {
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: url(${bgMintDone});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right center;
            .md-img {
                position: absolute;
                height: 80%;
                right: 5%;
                top: 9%;
                width: fit-content;
                display: flex;
                > img {
                    height: 100%;
                    width: auto;
                }
            }
        }
        .mint-role {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            margin-bottom: 24px;
            flex-wrap: wrap;
        }
        .mint-role-2 {
            text-align: center;
            margin-bottom: 72px;
        }
        .mint-start {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 24px;
        }
        .mint-action {
            background: rgba(175, 236, 255, 0.16);
            border-radius: 8px;
            width: 100%;
            padding: 32px;
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            .ma-row {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                &:first-child {
                    margin-bottom: 32px;
                }
                > span {
                    text-transform: capitalize;
                }
                .mar-price {
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    > span {
                        font-size: 24px;
                    }
                    > img {
                        width: 32px;
                        height: auto;
                        margin-left: 4px;
                    }
                }
                .amount-input {
                    border-radius: 4px;
                    border: 1px solid white;
                    height: 46px;
                    width: 232px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .aii {
                        flex: 1;
                        display: flex;
                        > .aii-input {
                            width: 100%;
                            background-color: unset;
                            padding: 0 12px;
                            text-align: center;
                        }
                    }
                    > div:nth-child(1),
                    > div:nth-child(3) {
                        width: 44px;
                        height: 100%;
                        border-right: 1px solid white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(255, 255, 255, 0.16);
                        cursor: pointer;
                        > span {
                            font-size: 24px;
                        }
                    }
                    > div:nth-child(3) {
                        border-right: none;
                        border-left: 1px solid white;
                    }
                }
                > button {
                    width: 102px;
                    margin-left: 24px;
                }
            }
        }
        .mint-end {
            display: flex;
            align-items: center;
        }
        .mint-range {
            margin-top: 72px;
            margin-bottom: 20px;
            width: 100%;
        }
    }
    .mint-wrap-full {
        width: 100%;
        position: relative;
        .mint {
            width: 60%;
            justify-content: center;
            padding-bottom: 10%;
        }
    }
    .mint-img {
        position: fixed;
        bottom: 4%;
        right: 60px;
        width: 40%;
        height: 78.9vh;
        display: flex;
        z-index: 1;
        > img {
            object-fit: contain;
            object-position: center right;
        }
    }
    .mint-nologin {
        width: 50%;
        padding: 100px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        margin-top: 8%;
        > span {
            margin-bottom: 50px;
        }
        > button {
            max-width: 260px;
        }
    }
    ${breakpointsMedias.max1199} {
        margin-top: 0;
    }
    ${breakpointsMedias.max991} {
        padding-top: 118px;
        padding-bottom: 54px;
        .mint-wrap {
            width: 100%;
            min-height: unset;
            max-height: unset;
            flex-direction: column;
            max-width: 400px;
            margin: 0 auto;
            .mint {
                padding: 32px 23px;
                padding-bottom: 32px;
                width: 100%;
            }
            .mint-done {
                width: 100%;
                height: fit-content;
                position: unset;
                background-image: url(${bgMintDone2});
                background-size: auto 100%;
                background-repeat: no-repeat;
                background-position: center bottom;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 32px;
                padding-top: 35%;
                margin-top: -18%;
                .md-img {
                    position: unset;
                    height: fit-content;
                    width: 100%;
                    display: flex;
                    max-width: 400px;
                    > img {
                        height: auto;
                        width: 100%;
                       
                    }
                }
            }
            .mint-role {
                margin-bottom: 24px;
            }
            .mint-role-2 {
                margin-bottom: 48px;
            }
            .mint-start {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-bottom: 16px;
            }
            .mint-action {
                padding: 16px;
                .ma-row {
                    &:first-child {
                        margin-bottom: 24px;
                    }
                    .mar-price {
                        > span {
                            font-size: 20px;
                        }
                        > img {
                            width: 24px;
                        }
                    }
                    .amount-input {
                        height: 43px;
                        width: 150px;
                        > div:nth-child(1),
                        > div:nth-child(3) {
                            width: 42px;
                           
                        }
                    }
                    > button {
                        width: 102px;
                        margin-left: 16px;
                    }
                }
            }
            .mint-range {
                margin-top: 48px;
                width: 100%;
            }
        }
        .mint-wrap-full {
            width: 100%;
            position: relative;
            .mint {
                width: 100%;
                justify-content: center;
                padding-bottom: 32px;
            }
            .mint-range {
                margin-top: 0;
                margin-bottom: 10px;
            }
        }
        .mint-img {
            display: none;
        }
        .mint-nologin {
            width: 100%;
            max-width: 500px;
            padding: 100px 24px;
            margin: 8% auto 0 auto;
            > span {
                margin-bottom: 30px;
            }
            > button {
                max-width: 200px;
            }
        }
    }
    ${breakpointsMedias.max767} {
        margin-top: 0;
    }
`