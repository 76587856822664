import { Address, useContractRead } from 'wagmi'
import abiGenesys from 'helpers/abi-genesys.json'
import { useMemo } from 'react'

interface ICheckMinted {
    address: string,
    round: number
}

const useCheckMinted = ({ address, round }: ICheckMinted) => {
    const { data: dataMinted } = useContractRead({
        address: process.env.REACT_APP_CONTRACT_GENESYS as Address,
        abi: abiGenesys,
        functionName: "minted",
        args: [address, round],
        watch: true,
        cacheTime: 2_000,
    })

    const minted = useMemo(() => {
        if (dataMinted) {
            return Number(dataMinted)
        }
        return 0
    }, [dataMinted])

    return { minted }
}

export default useCheckMinted