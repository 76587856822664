import iconLogo from 'assets/images/logo.svg'
import { breakpointsMedias } from 'constants/breakpoints'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Logo = () => {
    return (
        <Wrap>
            <Link to="" className="logo">
                <img className='' src={iconLogo} alt="" />
            </Link>
        </Wrap>
    )
}

export default Logo

const Wrap = styled.div`
    width: 138px;
    height: fit-content;
    cursor: pointer;
    border-radius: 50%;
    .logo {
        width: 100%;
        height: fit-content;
        > img {
            width: 100%;
            height: auto;
        }
    }
    ${breakpointsMedias.min1200} {
        width: 200px;
        margin-right: 22px;
    }
    ${breakpointsMedias.min1360} {
        width: 276px;
        margin-right: 22px;
    }
`