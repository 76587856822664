import { createGlobalStyle } from "styled-components";
import { breakpointsMedia, breakpointsMedias } from "./breakpoints";
import configColor from "./configColor";

export const GlobalStyle = createGlobalStyle`
//toast
  .Toastify__toast-container {
    -webkit-transform: translate3d(0,0,1px);
      transform: translate3d(0,0,1px);
  }
  //container
  .Toastify__toast {
    border-radius: 10px;
    border: 1px solid ${configColor.gray5};
    padding:12px 12px 12px 12px;
    &.small-toast{
      width: 200px;
    }
    ${breakpointsMedia.largeDesktop}{
      padding:20px 20px 20px 20px;

    }
  }
  .Toastify__toast-theme--light {

}
  //progress
  .Toastify__progress-bar {
    /* display: none; */
    bottom: 20px;
    opacity: 1;
    left: 20px;
    width: calc(100% - 40px);
    height: 4px;
    /* :before{
      content:'';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 4px;
      z-index: var(--toastify-z-index);
      opacity: 0.7;
      transform-origin: left;
      background-color: #ccc;
      z-index: -1;
    } */
    ${breakpointsMedia.largeDesktop}{
      width: calc(100% - 24px);

    }
  }
  //button
  .Toastify__close-button {
    opacity: 1;
    position: absolute;
    right: 15px;
    top:15px;
  }
  .Toastify__close-button--default {
  }
  .Toastify__close-button > svg {
    color: ${configColor.gray4};
    width: 18px;
    height: 18px;
  }
  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
  }
  //icon
  /** Used to position the icon **/
.Toastify__toast-icon {
  display: none;
}
//end-toast
  :root{
     
    --toastify-color-light: ${configColor.gray2};
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: ${configColor.colorPrimary};
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 330px;
    --toastify-toast-background: ${configColor.white};
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: ${configColor.white};
    --toastify-text-color-dark: #fff;

    //Used only for colored theme
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: ${configColor.white};
    --toastify-spinner-color-empty-area: #e0e0e0;

    // Used when no type is provided
    // toast("**hello**")
    --toastify-color-progress-light: linear-gradient(
      to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55
    );
    // Used when no type is provided
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
  
  }
  :root{
    font-size:13px;
  }
  body {
    background-color: ${configColor.colorBG};
    font-size:13px;
    line-height:1.17;
    font-family: 'Roboto';
  }
  .scrollbar {
    scrollbar-width: thin;
    &::-webkit-scrollbar {
            width: 4px !important;
            height: 4px !important;
            margin-top:10px;
        }
        &::-webkit-scrollbar-track {
            background: ${configColor.gray2} !important;
            border-radius: 6px !important;
        }
        &::-webkit-scrollbar-thumb {
            background: ${configColor.gray3} !important;
            border-radius: 6px !important;
        }
  }
  .container2{
      width:100%;
      padding-left:16px;
      padding-right:16px;
      margin-left:auto;
      margin-right:auto;
      /* max-width:1170px; */
      &.full{
        max-width:100vw;
      }
  }
  .container {
    width: 100%;
    padding: 0 54px;
    max-width: 1540px;
    ${breakpointsMedias.max1199}{
      padding: 0 16px;
      max-width: 1540px;
    }
  }
  .page-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 7px 12px;
        border-radius: 4px;
        background: ${configColor.gray2};
        margin-top: 6px;
        .pc-item {
          margin: 0 13px;
            &:hover {
                color: ${configColor.lightWhite};
            }
        }
        .pc-item-active {
            color: ${configColor.colorPrimary} !important;
        }
        .pc-prev:hover {
            color: ${configColor.lightWhite};
        }
        .pc-next:hover {
            color: ${configColor.lightWhite};
        }
    }
    .color-primary {
      color: ${configColor.colorPrimary};
    }
  ${breakpointsMedia.mobileS}{
      .container2{
        padding-left:10px;
        padding-right:10px;
      }
  }
  ${breakpointsMedia.landscape}{
        .container2{
            /* max-width:100%; */
            padding:0 24px;
        }
        :root{
          font-size:16px;
        }
        body {
          font-size:16px;
        }
    }
  ${breakpointsMedia.tablet}{
  }
  ${breakpointsMedia.desktop}{
        .container2{
            padding:0 40px;
        }

    }

  ${breakpointsMedia.largeDesktop}{
      :root{
          font-size:16px;
          --toastify-toast-width: 455px;
        --toastify-toast-background: ${configColor.white};
        --toastify-toast-min-height: 64px;
        --toastify-toast-max-height: 800px;
        --toastify-font-family: sans-serif;
        }
        body {
          font-size:16px;
        }
        .container2{
            /* max-width:1150px; */
            padding:0 40px;
        }
    }
  /* ${breakpointsMedia.xLargeDesktop}{
      :root{
          font-size:16px;
        }
        body {
          font-size:16px;
        }
        .container2{
            padding:0 40px;
        }
    } */
  ${breakpointsMedia.xxLargeDesktop}{
        .container2{
            max-width:1416px;
            padding: 0;
        }
    }
`;
