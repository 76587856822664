import styled from 'styled-components'
import { useEffect } from 'react'
import iconClose from 'assets/images/icon-close.png';
import { breakpointsMedias } from 'constants/breakpoints';

interface IWrapFrame {
    children: React.ReactElement;
    className?: string
}

const WrapFrame = ({ className, children }: IWrapFrame) => {

    return (
        <Wrap className={`${className}`}>
            <div className="wf-top"></div>
            <div className="wf-left"></div>
            <div className="wf-right"></div>
            {children}
        </Wrap>
    )
}

export default WrapFrame

const Wrap = styled.div`
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8.4px);
    border-radius: 8px 8px 0px 0px;
    position: relative;
    .wf-top {
        position: absolute;
        width: 100%;
        height: 20px;
        border: 1px solid #ffffff6a;
        border-radius: 8px 8px 0px 0px;
        border-bottom: none;
        top: 0;
        left: 0;
        /* z-index: 0; */
    }
    .wf-left {
        position: absolute;
        width: 1px;
        height: calc(100% - 40px);
        background: linear-gradient(to bottom, #ffffff6a 0%, rgba(255, 255, 255, 0) 100%);
        top: 20px;
        left: 0;
        /* z-index: 0; */
    }
    .wf-right {
        position: absolute;
        width: 1px;
        height: calc(100% - 40px);
        background: linear-gradient(to bottom, #ffffff6a 0%, rgba(255, 255, 255, 0) 100%);
        top: 20px;
        right: 0;
        /* z-index: 0; */
    }
    ${breakpointsMedias.max1199} {
        &::after {
            border-radius:  0 0 10px 10px;
        }
    }
`